import {lazy, Suspense} from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
const RegisterPage = lazy(() => import('./Modules/RegisterPage/index'));
const EnquiryWalkIn = lazy(() => import('./Modules/EnquiryWalkIn/index'));
const VerificationPage = lazy(() => import('./Modules/VerificationPage/index'));
const ResultPage = lazy(() => import('./Modules/ResultPage/index'));
const IntakePage = lazy(() => import('./Modules/IntakePage/index'));
const IntakeResultPage = lazy(() => import('./Modules/IntakeResultPage/index'));
const MediaConsentPage = lazy(() => import('./Modules/MediaConsentPage/index'));
const Error404 = lazy(() => import('./Modules/Error/404'));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path={'/'} component={RegisterPage} />
            <Route path={`${process.env.PUBLIC_URL}/enquiry-walk-in`} component={EnquiryWalkIn} />
            <Route path={`${process.env.PUBLIC_URL}/verification`} component={VerificationPage} />
            <Route path={`${process.env.PUBLIC_URL}/intake`} component={IntakePage} />
            <Route path={`${process.env.PUBLIC_URL}/intake-result`} component={IntakeResultPage} />
            <Route path={`${process.env.PUBLIC_URL}/media-consent`} component={MediaConsentPage} />
            <Route path={`${process.env.PUBLIC_URL}/result`} component={ResultPage} />
            <Route component={Error404} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
